import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import SEO from "../components/seo"
import styled from "@emotion/styled"
import { jsx, css } from "@emotion/core"
import { smsRegisterCard, smsDonate } from "../state/api"
import Logo from "../assets/images/svg/logo.svg"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Helmet from "react-helmet"
import InputMask from "react-input-mask"
import { navigate } from "gatsby"
import { Redirect } from "@reach/router"
import { setDonationComplete } from "../state/app"

const smsForm = ({ theme }) => css`
  font-family: "Roboto", sans-serif;
  color: #585858;

  h1,
  h2 {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 16px;
    color: #203760;
  }

  h1 {
    margin-top: 0;
  }

  img {
    margin: 35px auto;
    display: block;
  }

  .campo {
    display: flex;
    flex-direction: column;
  }

  input {
    background-color: #f6f6f6;
    border: 1px solid #cdcdcd;
    border-radius: 2px;
    height: 30px;
    margin-bottom: 15px;
    padding-left: 10px;
  }

  label {
    margin-bottom: 5px;
    font-size: 14px;
  }

  .user-warning {
    display: block;
    margin: 30px 20px 0 20px;
    font-size: 12px;
  }

  button {
    margin: 10px 20px 20px 20px;
    width: calc(100% - 40px);
    height: 40px;
    background-color: rgb(32, 55, 96);
    color: white;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    font-size: 16px;

    &:disabled {
      cursor: default;
    }
  }

  .container {
    background-color: white;
    padding: 20px;
    margin: 0 -8px;
    box-shadow: rgba(200, 200, 200, 0.6) 5px 5px 15px 5px;
  }

  p {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    color: #203760;
    margin-bottom: 10px;

    span {
      font-size: 14px;
    }
  }
`

const CadastroSMS = ({ location }) => {
  const dispatch = useDispatch()
  const donationCompleted = useSelector(state => state.app.donationCompleted)

  const params = window.location.search
  const converted = atob(params.split("m=")[1])
  const [shortCode, phoneNumber, message, userId] = converted.split(":")

  const [cardNumber, setCardNumber] = useState("")
  const [cardName, setCardName] = useState("")
  const [cardDate, setCardDate] = useState("")
  const [cardCode, setCardCode] = useState("")
  const [loading, setLoading] = useState(false)
  const [cardRegistered, setCardRegistered] = useState(false)
  const [cardMask, setCardMask] = useState("9999-9999-9999-9999")

  const donationValue = (message || "").split(" ")[1] || "0,00"

  const registerCard = () => {
    setLoading(true)

    if (cardRegistered) {
      donate()
      return
    }

    const data = {
      holder_name: cardName,
      card_number: cardNumber,
      security_code: cardCode,
      expiration_month: cardDate.split("/")[0],
      expiration_year: `20${cardDate.split("/")[1]}`,
    }

    smsRegisterCard(data, userId, shortCode)
      .then(response => {
        if (response.status === 201) {
          donate()
          setCardRegistered(true)
        } else {
          toast.error("Algo deu errado, tente novamente mais tarde.")
          setLoading(false)
        }
      })
      .catch(e => {
        toast.error(
          ((e.response || {}).data || {}).Message ||
            "Algo deu errado, tente novamente mais tarde."
        )
        setLoading(false)
      })
  }

  const donate = () => {
    const data = {
      callbackMoRequest: {
        mobile: phoneNumber,
        body: message,
      },
    }

    smsDonate(data)
      .then(response => {
        if ((response.data || {}).data) {
          setCardRegistered(false)
          showConfirmation(response.data.data)
        } else {
          toast.error("Algo deu errado, tente novamente mais tarde.")
        }
      })
      .catch(e => {
        toast.error(
          ((e.response || {}).data || {}).Message ||
            "Algo deu errado, tente novamente mais tarde."
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const showConfirmation = data => {
    localStorage.setItem("iurd-donation-sms-data", JSON.stringify(data))
    navigate(`/confirmacaoSMS`)
  }

  const updateCardMask = value => {
    let mask = "9999-9999-9999-9999"
    if (/^3[47]/.test(value)) {
      mask = "9999-999999-99999"
    }
    setCardMask(mask)
  }

  if (donationCompleted) {
    dispatch(setDonationComplete(false))
    return <Redirect to="/pt" noThrow />
  }

  return (
    <>
      <SEO title="Cadastro SMS" />
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
        />
      </Helmet>
      <div css={smsForm}>
        <img src={Logo} alt="Logo da Universal" />
        <form>
          <div className="container">
            <h1>Informações do Cartão</h1>
            <div className="infomacao-cartao">
              <div className="campo">
                <label>Número do Cartão</label>
                <InputMask
                  id="fullExpDate"
                  mask={cardMask}
                  placeholder="____-____-____-____"
                  maskChar={null}
                  onChange={e => {
                    setCardNumber(e.target.value)
                    updateCardMask(e.target.value)
                  }}
                  value={cardNumber}
                />
              </div>
              <div className="campo">
                <label>Nome no Cartão</label>
                <input
                  type="text"
                  id="cardName"
                  required
                  onChange={e => setCardName(e.target.value)}
                  value={cardName}
                />
              </div>
              <div className="campo">
                <label>Data de Expiração</label>
                <InputMask
                  id="fullExpDate"
                  mask="99/99"
                  placeholder="__/__"
                  maskChar={null}
                  onChange={e => setCardDate(e.target.value)}
                  value={cardDate}
                />
              </div>
              <div className="campo">
                <label>Código de Verificação</label>
                <InputMask
                  id="cardCode"
                  mask="9999"
                  placeholder="____"
                  maskChar={null}
                  onChange={e => setCardCode(e.target.value)}
                  value={cardCode}
                />
              </div>
              <p>
                R$ {donationValue} <br />
                <span>Valor da doação</span>
              </p>
            </div>
          </div>
          <span className="user-warning">
            Esse cadastro será necessário uma única vez.
          </span>
          <button type="button" onClick={registerCard} disabled={loading}>
            {loading ? "Aguarde" : "Confirmar e doar"}
          </button>
        </form>
      </div>
    </>
  )
}

export default CadastroSMS
